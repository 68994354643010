@font-face {
  font-family: 'Alibaba-PuHuiTi-Light';
  src: url('./Alibaba-PuHuiTi-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Alibaba-PuHuiTi-Medium';
  src: url('./Alibaba-PuHuiTi-Medium.otf');
}

@font-face {
  font-family: 'dincond-regular';
  src: url('./dincond-regular.otf');
}