* {
  box-sizing: border-box;
  // font-family: 'Alibaba-PuHuiTi-Light';
  // color: #666
}
a {
  text-decoration: none;
}
.ant-btn-primary {
  span {
    color: #fff;
  }
}
.grayBtn {
  span {
    color: #484848;
  }
}
body {
  margin: 0;
  height: 100vh;
  background: #f6f6f6;
}
ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

input,button {
	outline: none;
	border: none;
	box-sizing: border-box;
	border-radius: 3px;
}
h1,h2,h3,h4,h5,h6,p {
	margin: 0;
}
.grayBtn {
  background-color: #dcdcdc !important;
}
.ant-table-body {
  overflow: auto!important;
}
/* menu选中样式 */
.ant-menu {
  a {
    color: #484848;
  }
  .ant-menu-item-selected {
    background-color: #f2f2f2;
  }
}
// 弹窗title文字居中
.ant-modal-title {
  text-align: center;
}
// 取消safari input框后面的小图标
input{
  &::-webkit-credentials-auto-fill-button {
    display: none !important;
    visibility: hidden;
    pointer-events: none;
    position: absolute; 
    right: 0;
  }
  &:focus::-webkit-contacts-auto-fill-button{
    opacity: 0;
  }
}

/** 高亮table样式 */
.row-yellow {
  background-color: #FEEDC7;
}
.row-darkYellow {
  background-color: #FFF9B6;
}
.row-lightRed {
  background-color: #FFD3D3;
}
.row-highlight{
  background-color: #13c2c2;
}
.ant-table-cell-row-hover {
  background-color: #87e8de!important;
}

/**  */
// 隐藏表单必填*号
.ant-form-item-required::before {
  display: none !important;
}
.ant-form-inline .ant-form-item {
  margin-bottom: 20px !important;
  // .ant-form-item-label {
    // width: auto!important
  // }
  .ant-form-item-control {
    flex-basis: 0;
  }
  .ant-form-item-label >label {
    min-height: 32px;
    height: auto !important;
  }
}

// @media (max-width: 575px) {
//   .ant-form-item .ant-form-item-label >label::after {
//       display: block !important;
//   }
// }
@media (max-width: 575px) {
  .App {
    overflow: auto;
    background-color: #fff;
  }
  .ant-form .ant-form-item .ant-form-item-row .ant-form-item-label {
      flex: 0 0 auto;
      max-width: 100%;
  }
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 1 !important;
  }
}
.ant-upload {
  width: 100%;
}

.ant-checkbox-group {
  flex-wrap: wrap;
  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-wrapper {
    margin-right: 8px;
    &:last-child {
      margin: 0;
    }
  }
}



.ant-tabs-content {
  height: 100%;
  .ant-tabs-tabpane {
    height: 100%;
  }
}


.ant-upload-list-item {
  max-width: 200px;
}

.flex {
  display: flex;
}